import { Navbar, Button } from 'react-bulma-components';
// import { useAuth0 } from '@auth0/auth0-react';
import { useUser } from './User';

function Nav() {
  const { user, isAuthenticated, loginWithRedirect } = useUser();

  return (
    <Navbar role="navigation">
      <Navbar.Brand>
        <Navbar.Item>diurnal.ink</Navbar.Item>
        <Navbar.Burger />
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Container>
          <Navbar.Item>
            <Navbar.Link arrowless={true}>Today</Navbar.Link>
          </Navbar.Item>
          <Navbar.Item>
            <Navbar.Link arrowless={true}>History</Navbar.Link>
          </Navbar.Item>
        </Navbar.Container>
        <Navbar.Container align="right">
          {!isAuthenticated && (
            <Navbar.Item>
              <Button color="primary" onClick={() => loginWithRedirect()}>
                Login
              </Button>
            </Navbar.Item>
          )}
          {isAuthenticated && (
            <Navbar.Item>
              {user?.name}
            </Navbar.Item>
          )}
        </Navbar.Container>
      </Navbar.Menu>
    </Navbar>
  );
}

export default Nav;
