import React, { FormEvent } from 'react';
// import logo from './logo.svg';
// import './App.css';
import { Progress, Level, Heading, Form } from 'react-bulma-components';

interface Props {
  goalWordCount: number;
}

interface State {
  wordCount: number;
}

export default class Editor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { wordCount: 0 };
  }
  countWords = (text: string) => {
    text = text.replace(/^\s+|\s+$/g, '');
    if (text.length === 0) {
      return 0;
    }
    return text.split(/\s+/).length;
  };
  onTextInput = (e: FormEvent<HTMLTextAreaElement>) => {
    const words = this.countWords(e.currentTarget.value || '');
    this.setState({ wordCount: words });
  };
  render() {
    return (
      <div className="editor content">
        <Level mb="1">
          <Level.Side align="left">
            <Level.Item>Monday, 28th June 2021</Level.Item>
          </Level.Side>
          <Level.Side align="right">
            <Level.Item>Progress: {this.state.wordCount}/{this.props.goalWordCount} words</Level.Item>
          </Level.Side>
        </Level>
        <Progress
          mb={2}
          value={this.state.wordCount}
          max={this.props.goalWordCount}
          color="primary"
        />
        <Form.Field>
          <Form.Textarea
            radiusless={true}
            shadowless={true}
            rows={10}
            placeholder="Write anything..."
            onInput={this.onTextInput}
          />
        </Form.Field>
      </div>
    );
  }
}
