import React from 'react';
import logo from './logo.svg';
//import 'bulma/css/bulma.min.css';
import './mystyles.scss';
import { Section, Block, Navbar, Button, Progress } from 'react-bulma-components';
import Editor from './Editor';
import Nav from './Nav';
// import './App.css';
import { useQuery, gql } from '@apollo/client';
import { UserProvider } from './User';

const HEALTHCHECK = gql`
  query HealthCheck {
    healthcheck
  }
`;

function App() {
  const { loading, error, data } = useQuery(HEALTHCHECK);
  console.log(data);
  if (loading) return <p>Loading...</p>;
  if (error) {
    console.log(error);
    return <p>Error :(</p>;
  }
  return (
    <UserProvider>
      <div className="App">
        <Nav />
        <Section>
          <Editor goalWordCount={500} />
        </Section>
      </div>
    </UserProvider>
  );
}

export default App;
