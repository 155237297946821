import React from 'react';

type User = {
  name: string;
  id: string;
};

type ContextType = {
  user?: User;
  isAuthenticated: boolean;
  isLoading: boolean;
  loginWithRedirect: Function;
};

const UserContext = React.createContext<ContextType>({
  user: undefined,
  isAuthenticated: false,
  isLoading: false,
  loginWithRedirect: () => {},
});

export function useUser() {
  return React.useContext(UserContext);
}

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = React.useState<User>({ name: '', id: '' });
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const value = {
    user: user,
    isAuthenticated: isAuthenticated,
    isLoading: false,
    loginWithRedirect: () => {
      window.location.href = '/auth/login';
    },
  };
  React.useEffect(() => {
    const fetchUser = async () => {
      const response = await fetch(`/user/profile`);
      const newUser = await response.json();
      setUser(newUser);
      setIsAuthenticated(newUser.id !== '');
    };
    fetchUser();
  }, []);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
